
.card-revenue {
  background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
  background-size: cover;
  color: color(white);

  .highlight-text {
    font-size: 1.875rem;
    font-family: $type1;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 8px;
  }

  .badge {
    background-color: rgba(white, .2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem;
  }
}

.card-revenue-table {
  .revenue-item {
    border-bottom: 1px solid $border-color;
    @extend .py-3;

    &:last-child {
      border-bottom: 0;
      @extend .pb-0;
    }

    &:first-child {
      @extend .pt-0;
    }

    .revenue-desc {
      margin-right: auto;
      width: 80%;

      p {
        margin-bottom: 0;
      }
    }

    .revenue-amount {
      margin-left: auto;
      width: 40%;

      p {
        font-size: 1.25rem;
        font-family: $type1;
        font-weight: 600;
        text-align: right;

        .rtl & {
          text-align: left;
        }
      }
    }
  }
}
