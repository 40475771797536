/* Sidebar */

.sidebar {

    &.editor-sidebar, &.lead-partner-sidebar {
        background: $sidebar-dark-profile-name-color;
    }

    &.admins-sidebar {
        // background: black;

        // .admins-sidebar-title {
        //     font-weight: 800;
        //     color: $white;
        // }
    }
    // min-height: 100vh;
    background: $theme-primary;
    font-family: $type1;
    font-weight: 700;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 11;
    transition: width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function,
        background $action-transition-duration $action-transition-timing-function;

    &.no-bg {
        background: transparent;
        width: 250px;
        min-height: 100%;

        .nav {
            .nav-item {
                .nav-link {
                    color: $theme-primary;

                    .menu-icon {
                        filter: invert(16%) sepia(50%) saturate(1159%) hue-rotate(157deg) brightness(40%) contrast(102%);
                    }
                    .menu-title{
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .sidebar-brand-wrapper {
        width: auto;
        // height: $navbar-height;
        border-bottom: 1px solid $white;



        .sidebar-brand {
            // padding: 2.25rem 0 0.25rem 40px;
            text-align: center;
            display: inline-block;
            margin: 0 auto;

            .rtl & {
                padding: 20px 10px 10px 0;
            }

            img {
                width: calc(#{$sidebar-width-lg} - 60px);
                max-width: 100%;
                // height: 48px;
                margin: auto;
                margin-top: 30px;
                margin-bottom: 30px;
                vertical-align: middle;
            }
        }

        .brand-logo-mini {
            display: none;

            img {
                width: 35px;
                max-width: 100%;
                height: 35px;
                margin: auto;
            }
        }
    }

    .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;

        .divider-item {
            border-bottom: 1px solid $white;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .nav-item {
            cursor: pointer;

            .collapse {
                z-index: 999;
            }

            .collapse.show,
            .collapsing {
                background: $sidebar-light-menu-active-bg;
            }

            .nav-link {
                align-items: center;
                display: flex;
                padding: $sidebar-menu-padding;
                white-space: nowrap;
                height: $nav-link-height;
                color: $ghost-white;

                i {
                    &.menu-arrow {
                        margin-left: auto;
                        margin-right: 0;
                        transition-duration: 0.2s;
                        transition-property: transform;
                        transition-timing-function: ease-in;

                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                    }
                }

                &[aria-expanded="true"] {
                    background: $sidebar-light-menu-active-bg;

                    i {
                        &.menu-arrow {
                            transform: rotate(90deg);
                        }
                    }
                }

                .menu-icon {
                    margin-right: 1.25rem;
                    width: $sidebar-icon-size;
                    height: $sidebar-icon-size;
                    line-height: 1;
                    font-size: 18px;
                    color: lighten($sidebar-light-menu-icon-color, 30%);

                    .rtl & {
                        margin-right: 0;
                        margin-left: 1.25rem;
                    }
                }

                .menu-title {
                    color: inherit;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                    cursor: pointer;
                }

                .badge {
                    margin-left: auto;
                }

                &:hover {
                    color: darken($sidebar-light-menu-color, 5%);
                }
            }

            &.active {
                >.nav-link {
                    color: $theme-primary;
                    background: $white;

                    .menu-title {
                        color: inherit;
                    }

                    img {
                        filter: invert(16%) sepia(50%) saturate(1159%) hue-rotate(157deg) brightness(40%) contrast(102%);
                    }

                    &.sub-menu-link {
                        color: $ghost-white;
                        background-color: #003348;

                        img {
                            filter: invert(0%);
                        }
                    }
                }
            }

            &.nav-profile {
                .nav-link {
                    display: flex;
                    flex-direction: column;
                    height: auto;

                    .profile-image {
                        width: 40px;
                        height: 40px;
                        margin-right: 1rem;

                        .rtl & {
                            margin-right: 0;
                            margin-left: 1rem;
                        }

                        img {
                            border-radius: 100%;
                            max-width: 100%;
                        }
                    }

                    .text-wrapper {
                        margin-left: 15px;

                        .rtl & {
                            margin-left: 0;
                            margin-right: 15px;
                        }
                    }

                    .profile-name {
                        font-weight: 700;
                        margin-bottom: 2px;
                        line-height: 1;
                        color: $white;
                    }

                    .designation {
                        margin-right: 3px;
                        font-weight: 700;
                        line-height: 1;
                        color: $white;

                        .rtl & {
                            margin-right: 0;
                            margin-left: 3px;
                        }
                    }

                    .dropdown {
                        .dropdown-toggle {
                            &.arrow-hide {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .dropdown {
                        position: unset;

                        .user-switch-dropdown-toggler {
                            display: flex;
                            flex-direction: row;
                            padding: 1px 0px;
                            margin-bottom: 30px;

                            &:focus {
                                outline: 0;
                                box-shadow: none;
                            }
                        }

                        .dropdown-menu {
                            top: 0;
                        }
                    }
                }
            }

            &.not-navigation-link {
                position: relative;
            }
        }

        &:not(.sub-menu) {
            >.nav-item {
                &:hover {
                    &:not(.nav-profile) {
                        >.nav-link {
                            color: $theme-primary;
                            background: $white;

                            img {
                                filter: invert(16%) sepia(50%) saturate(1159%) hue-rotate(157deg) brightness(40%) contrast(102%);
                            }
                        }
                    }


                }
            }
        }

        &.sub-menu {
            margin-bottom: 0;

            .rtl & {
                padding: 0 4rem 0 0;
            }

            .nav-item {
                .nav-link {
                    background-color: lighten(#003348, 5%);
                    color: $ghost-white;
                    padding: $sidebar-submenu-item-padding;
                    font-size: $sidebar-submenu-font-size;
                    line-height: 1;
                    height: auto;

                    .menu-icon {
                        height: $sidebar-sub-icon-size;
                        width: $sidebar-sub-icon-size;
                        margin-right: .75rem;
                    }

                    &.active {
                        color: $ghost-white;
                        background: transparent;

                        &:before {
                            content: '';
                            background: $sidebar-light-submenu-color;
                            height: 25px;
                            width: 5px;
                            position: absolute;
                            left: 1px;
                            border-radius: 5px;
                        }
                    }
                }

                &:hover {
                    >.nav-link {
                        background: $sidebar-light-submenu-hover-bg;
                        color: darken($ghost-white, 10%);

                        &:before {
                            background: $sidebar-light-submenu-hover-color;
                        }
                    }
                }
            }
        }
    }
}

//sidebar color variation
.sidebar-dark {
    .sidebar {
        background: $sidebar-dark-bg;

        .nav {
            .nav-item {

                .collapse.show,
                .collapsing {
                    background: $sidebar-dark-menu-active-bg;
                }

                .nav-link {
                    color: $sidebar-dark-menu-color;

                    &[aria-expanded="true"] {
                        background: $sidebar-dark-menu-active-bg;
                    }

                    i:not(.btn i) {
                        color: $sidebar-dark-menu-icon-color;

                        &.menu-arrow {
                            &:before {
                                color: rgba($sidebar-dark-menu-color, 0.5);
                            }
                        }
                    }

                    &:hover {
                        color: darken($sidebar-dark-menu-color, 5%);
                    }
                }

                &.nav-profile {
                    .profile-name {
                        .name {
                            color: $white;
                        }

                        .designation {
                            color: $white;
                        }
                    }

                    .notification-panel {
                        &:before {
                            background: $sidebar-dark-profile-name-color;
                        }

                        >span {
                            background: $sidebar-dark-menu-active-bg;

                            i {
                                color: color(gray-light);
                            }
                        }
                    }
                }

                &.active {
                    >.nav-link {
                        color: $sidebar-dark-menu-active-color;
                    }
                }

                .sidebar-sticker {
                    background: $sidebar-dark-menu-active-bg;
                }
            }

            &:not(.sub-menu) {
                >.nav-item {
                    &:hover {
                        &:not(.nav-profile) {
                            >.nav-link {
                                background: $sidebar-dark-menu-hover-bg;
                                color: $sidebar-dark-menu-hover-color;
                            }
                        }
                    }
                }
            }

            &.sub-menu {
                .nav-item {
                    .nav-link {
                        color: $sidebar-dark-submenu-color;

                        &.active {
                            color: $sidebar-dark-menu-active-color;

                            &:before {
                                background: $sidebar-dark-menu-active-color;
                            }
                        }
                    }

                    &:hover {
                        >.nav-link {
                            background: $sidebar-dark-submenu-hover-bg;
                            color: $sidebar-dark-submenu-hover-color;

                            &:before {
                                background: $sidebar-dark-submenu-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        left: -$sidebar-width-lg;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;

        &.active {
            left:0;
        }
    }

    .social-share-button{
        width: 100%;
    }
}
