:root {
    --theme-primary: #003348;
    --theme-secondary: #ffffff;
    --theme-gray: #898a8d;
    --theme-red: #8d181b;
    --theme-primary-light: #d1d5db;
    --text-color: #313131;
    --positive: #0DB814;
}

.scrollable-content-wrapper {
    margin-top: 35px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

input.mvb-custom {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 1.2rem;
}

.mvb-custom-label {
    font-weight: 600;
    font-size: 15px;
    color: #5b5b5b;
    text-transform: capitalize;
    margin-bottom: 0;
    span{
        text-transform: lowercase;
    }
}

.jodit-status-bar-link {
    display: none;
}

.jodit-toolbar__box {
    background-color: var(--theme-primary) !important;

    .jodit-toolbar-button {
        background: var(--theme-primary);

        .jodit-toolbar-button__button {
            .jodit-icon {
                stroke: var(--theme-secondary);
                fill: var(--theme-secondary);
            }
        }

        .jodit-toolbar-button__trigger svg {
            stroke: var(--theme-secondary);
            fill: var(--theme-secondary);
        }
    }
}

.form-card {
    background: #fcfcfc;
    padding: 18px 12px;
    border: 0.25px solid #898989;
    box-sizing: border-box;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cancel-button {
    cursor: pointer;
    color: var(--theme-red);
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    font-size: 15px;
    font-weight: 500;

    svg {
        margin-right: 5px;
    }
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, .3) !important;
}

.confirm-alert {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: var(--white);
    box-shadow: 0 0 15px -8px #121212;
    color: var(--theme-primary);
    border-radius: 10px;

    button {
        width: 160px;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        color: var(--white);
        font-size: 14px;
        border-radius: 10px;
        border: 0;

        &.positive {
            background: var(--theme-primary);
        }

        &.negative {
            background: var(--theme-red);
        }
    }
}

.jodit-placeholder {
    z-index: 0 !important;
}

.social-share-button {
    display: flex;
    width: 65%;
    justify-content: space-around;
    margin: 20px auto 0;

    .clipboard-icon {
        background: #7f7f7f;
        width: 32px;
        display: flex;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;

        img {
            width: 17px; 
            height: 17px;
        }
    }
}